import React from 'react';
import { Icon, Layout, Row, Col } from 'antd';
import PropTypes from 'prop-types';
import MenuHeader from './MenuHeader';
import { Breadcrumb } from '../Shared';

const Header = ({ pathname, isCollapsed, showDrawer, drawerVisible }) => (
  <Layout.Header className="zs-header">
    <Row type="flex" align="middle" justify="space-between">
      <Col>
        {isCollapsed && (
          <Icon
            type={drawerVisible ? 'menu-fold' : 'menu'}
            onClick={showDrawer}
            className="zs-header-toggle-icon"
          />
        )}
        {!isCollapsed && <Breadcrumb />}
      </Col>
      <Col order={2} className="zs-align-right">
        <Row type="flex" align="middle" justify="end">
          <MenuHeader isCollapse={isCollapsed} pathname={pathname} />
        </Row>
      </Col>
    </Row>
  </Layout.Header>
);

Header.propTypes = {
  pathname: PropTypes.string,
  isCollapsed: PropTypes.bool,
  drawerVisible: PropTypes.bool,
  showDrawer: PropTypes.func,
};

Header.defaultProps = {
  isCollapsed: false,
  drawerVisible: false,
};

export default Header;
