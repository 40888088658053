import axios from 'axios';
import qs from 'qs';
import { notification } from 'antd';
import store, { history } from '~/config/store';
import { routeName } from '~/config/routes';

const baseURL = process.env.REACT_APP_API || 'http://localhost:3001';

const headers = {
  // Accept: 'application/json',
  'Content-Type': 'application/json',
};

const FetchApi = axios.create({
  baseURL,
  headers,
});

const FetchUpload = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

FetchApi.interceptors.response.use(
  response => response,
  error => {
    const status = error.response ? error.response.status : null;
    if (status === 401) {
      const message = error.response.data
        ? error.response.data.message
        : 'Unauthorized!';
      notification.error({ message });
      store.dispatch.auth.logout();
      return Promise.reject(history.push(routeName('login')));
    }
    if (status >= 500) {
      const response500 = {
        data: {
          message: '[ERR: 500] Terjadi kesalahan pada sistem!',
          errors: [],
        },
      };
      return Promise.reject(response500);
    }

    return Promise.reject(error.response);
  },
);

FetchUpload.interceptors.response.use(
  response => response,
  error => {
    const status = error.response ? error.response.status : null;
    if (status === 401) {
      const message = error.response.data
        ? error.response.data.message
        : 'Upload Unauthorized!';
      notification.error({ message });
    }
    return Promise.resolve(error.response);
  },
);

const instance = {
  setToken: token => {
    FetchApi.defaults.headers.Authorization = `Bearer ${token}`;
    FetchUpload.defaults.headers.Authorization = `Bearer ${token}`;
  },
  removeToken: () => {
    FetchApi.defaults.headers.Authorization = null;
    FetchUpload.defaults.headers.Authorization = null;
  },
  get: (url, data, cancelToken, otherOptions = {}) =>
    FetchApi({
      method: 'get',
      url: `${url}?${qs.stringify(data)}`,
      cancelToken,
      ...otherOptions,
    }),
  post: (url, data, cancelToken, otherOptions = {}) =>
    FetchApi({
      method: 'post',
      url,
      data,
      cancelToken,
      ...otherOptions,
    }),
  put: (url, data, cancelToken, otherOptions = {}) =>
    FetchApi({
      method: 'put',
      url,
      data,
      cancelToken,
      ...otherOptions,
    }),
  patch: (url, data, cancelToken, otherOptions = {}) =>
    FetchApi({
      method: 'patch',
      url,
      data,
      cancelToken,
      ...otherOptions,
    }),
  delete: (url, cancelToken, otherOptions = {}) =>
    FetchApi({
      method: 'delete',
      url,
      cancelToken,
      ...otherOptions,
    }),
  upload: file => {
    const formData = new FormData();
    formData.append('file', file);
    return FetchUpload.post('/file/upload', formData);
  },
  addToMedia: (module, module_id, key, filename) => {
    return FetchApi.post('/file/media', {
      module,
      module_id,
      key,
      filename,
    });
  },
  generateCancelToken: () => axios.CancelToken.source(),
};

export default instance;
