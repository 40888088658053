export const GUEST = 'guest';
export const LOGGED = 'logged';

export const URL_LOCALIZATION = '/locales/{{lng}}/{{ns}}.json';
export const VERSION_LOCALIZATION = 5;
export const TIME_CACHE_LOCALIZATION = 7 * 24 * 60 * 60 * 1000;
export const LANGUAGES = {
  id: 'id',
  en: 'en',
  default: 'en',
};

export const CUSTOMER_TYPE = {
  // regular: 'regular',
  reseller: 'reseller',
  marketplace: 'marketplace',
  advertisement: 'Advertisement / Iklan',
};

export const MARKETPLACE = [
  'Tokopedia',
  'Bukalapak',
  'Shopee',
  'Instagram',
  'Whatsapp',
  'Lazada',
  'Facebook',
];

export const COURIER = [
  'JNE',
  'TIKI',
  'SICEPAT',
  'POS',
  'NINJA',
  'WAHANA',
  'JNT',
  'OTHER',
];

export const ROLES = {
  SUPER_ADMIN: 'superadmin',
  ADMIN: 'admin',
  ACCOUNT: 'account',
  OPERATOR: 'operator',
};

export const PAYMENT_TYPE = ['cash', 'installment'];
export const PAYMENT_METHOD = ['cash', 'transfer', 'cod', 'cc', 'emoney'];

export const ADJUSTMENT_TYPE = {
  addition: 'Tambah Stok',
  subtraction: 'Kurangi Stok',
  change: 'Sinkronkan Stok',
};
