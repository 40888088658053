import React from 'react';
import secondaryLogo from '~/img/rst2.svg';
import primaryInverse from '~/img/rst.svg';
import primaryLogo from '~/img/rst-logo.svg';

// eslint-disable-next-line react/prop-types
const Logo = ({ style, type = 'primary', component }) => {
  let logo = primaryLogo;
  switch (type) {
    case 'primary':
      logo = primaryLogo;
      break;
    case 'secondary':
      logo = secondaryLogo;
      break;
    case 'inverse':
      logo = primaryInverse;
      break;
    default:
      break;
  }
  return (
    <div className="zs-logo">
      {component || (
        <img src={logo} style={{ width: 200, ...style }} alt="ERESTE269" />
      )}
    </div>
  );
};

export default Logo;
