import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Layout, Drawer } from 'antd';
import { MenuPrimary, Header } from './';
import { Logo } from '../Shared';
import bg from '~/img/bg.jpg';

const { Content, Sider } = Layout;

const MainLayout = ({ location, children }) => {
  const { pathname } = location;

  const [visible, setVisible] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const showDrawer = () => setVisible(true);
  const onClose = () => setVisible(false);
  const toggleCollapse = () => setIsCollapsed(!isCollapsed);

  return (
    <Layout className="zs-layout">
      <Sider
        width={250}
        trigger={null}
        breakpoint="md"
        collapsedWidth="0"
        collapsed={isCollapsed}
        onCollapse={toggleCollapse}
        style={{ background: `url(${bg})` }}
        className="zs-layout-sider">
        <Logo
          component={
            <img
              src="/images/logo-ereste-white.png"
              style={{ width: 200 }}
              alt="logo"
            />
          }
        />
        <div className="scrollable-menu">
          <strong className="zs-menu-title">Dashboard</strong>
          <MenuPrimary pathname={pathname} />
        </div>
      </Sider>
      <Drawer
        title={
          <Logo
            component={
              <img
                src="/images/logo-ereste-white.png"
                style={{ width: 200 }}
                alt="logo"
              />
            }
          />
        }
        placement="left"
        onClose={onClose}
        visible={visible}
        bodyStyle={{ padding: 0, margin: 0 }}>
        <MenuPrimary pathname={pathname} onClick={onClose} />
      </Drawer>
      <Layout
        className="zs-layout-section"
        style={{ marginLeft: isCollapsed ? 0 : 250 }}>
        <Header
          pathname={pathname}
          isCollapsed={isCollapsed}
          showDrawer={showDrawer}
          drawerVisible={visible}
        />
        <Content className="zs-layout-content">{children}</Content>
      </Layout>
    </Layout>
  );
};

MainLayout.propTypes = {
  location: PropTypes.object,
  children: PropTypes.node,
};

export default withRouter(MainLayout);
