import instance from '~/services/instance';
import { authenticationLogin } from '~/services/auth';
import { auth as authService } from '~/config/services';

const auth = {
  state: {
    token: null,
    user: {},
    permissions: [],
    error: null,
    isAuthenticated: false,
  },
  reducers: {
    setAuthenticated: (state, payload) => ({
      ...state,
      ...payload,
      isAuthenticated: !!payload.token,
    }),
    setUser: (state, user) => ({
      ...state,
      user,
    }),
    setPermissions: (state, permissions) => ({
      ...state,
      permissions,
    }),
    setLogout: () => ({
      token: null,
      user: {},
      error: null,
      isAuthenticated: false,
    }),
  },
  effects: {
    async authentication(credentials) {
      try {
        const { data } = await authenticationLogin(
          credentials.username,
          credentials.password,
        );
        const { user, token } = data.data;
        this.setAuthenticated({ user, token });
        instance.setToken(token);
      } catch (error) {
        this.setAuthenticated({ user: {}, token: null, error });
      }
    },
    async getMe() {
      try {
        const { data } = await instance.get(authService.me);
        const { data: user } = data;
        this.setUser(user);
      } catch (error) {
        this.setLogout();
      }
    },
    async getPermissions() {
      try {
        const { data } = await instance.get(authService.permissions);
        const { data: permissions } = data;
        this.setPermissions(permissions);
      } catch (error) {
        this.setPermissions([]);
      }
    },
    logout() {
      instance.removeToken();
      this.setLogout();
    },
  },
};

export default auth;
