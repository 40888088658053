import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import Title from './Title';

const HeaderSection = props => {
  return (
    <div className="header-section" {...props}>
      {props.title && (
        <Row
          type="flex"
          align="middle"
          justify="space-between"
          style={{ flex: 1 }}>
          <Col>
            <Title text={props.title} />
          </Col>
        </Row>
      )}
      {props.children}
    </div>
  );
};

HeaderSection.propTypes = {
  children: PropTypes.any,
  title: PropTypes.any,
};

export default HeaderSection;
