import instance from './instance';
import { auth } from '~/config/services';
import { validateEmail } from '~/utils/string';

export const authenticationLogin = (userName, password) => {
  const field = {
    username: userName,
    password,
  };
  if (validateEmail(userName)) {
    delete field.username;
    field.email = userName;
  }
  return instance.post(auth.login, field);
};
