import { lazy } from 'react';
import { generatePath } from 'react-router-dom';
import { createRoute } from '~/utils/general';
// import Home from '~/pages/Home';
import { LOGGED, GUEST } from './constants';
import MainLayout from '~/components/Layout/MainLayout';
import AuthLayout from '~/components/Layout/AuthLayout';
import { regexParam } from '~/utils/string';

const AsyncHome = lazy(() => import('../pages/Home.js'));
// const AsyncRegister = lazy(() => import('../pages/Register.js'));
// const AsyncForgotPass = lazy(() => import('../pages/ForgotPassword.js'));
const AsyncAbout = lazy(() => import('../pages/About.js'));
const AsyncLogin = lazy(() => import('../pages/Login.js'));
const AsyncUser = lazy(() => import('../features/user/List'));
const AsyncBrand = lazy(() => import('../features/brand/List'));
const AsyncCoupon = lazy(() => import('../features/coupon/List'));
const AsyncCustomer = lazy(() => import('../features/customer/List'));
const AsyncProduct = lazy(() => import('../features/product/List'));
const AsyncProductAdd = lazy(() => import('../features/product/Add'));
const AsyncProductEdit = lazy(() => import('../features/product/View'));
const AsyncEcommerce = lazy(() => import('../features/product/Ecommerce'));
const AsyncProductCategory = lazy(() =>
  import('../features/product/category/List'),
);
const AsyncLiveStock = lazy(() =>
  import('../features/product/LiveStockPage.js'),
);

const AsyncOrderList = lazy(() => import('../features/order/ListOrder'));
const AsyncOrderCreate = lazy(() => import('../features/order/CreateOrder'));
const AsyncOrderDetail = lazy(() => import('../features/order/DetailOrder'));
const AsyncOrderReport = lazy(() => import('../features/order/OrderReport'));

const AsyncCashflowTransaction = lazy(() =>
  import('../features/cashflow/List'),
);
const AsyncCashType = lazy(() => import('../features/cashflow/ListCashType'));
const AsyncCashflowDebit = lazy(() => import('../features/cashflow/ListDebit'));
const AsyncCashflowCredit = lazy(() =>
  import('../features/cashflow/ListCredit'),
);

const AsyncStockHistory = lazy(() => import('../features/stock/List'));
const AsyncStockAdjustment = lazy(() =>
  import('../features/stock/ListAdjustment'),
);
const AsyncStockAdjustmentCreate = lazy(() =>
  import('../features/stock/CreateAdjustment'),
);
const AsyncStockAdjustmentDetail = lazy(() =>
  import('../features/stock/DetailAdjustment'),
);

const AsyncGuestAccount = lazy(() => import('../features/user/GuestList'));
const AsyncReportProfitLoss = lazy(() =>
  import('../features/reports/ProfitLoss'),
);
const AsyncReportProductSummary = lazy(() =>
  import('../features/reports/ProductSummaryDetail'),
);
const AsyncReportOrderSummary = lazy(() =>
  import('../features/reports/OrderSummaryDetail'),
);

const routes = [
  {
    path: '/dashboard',
    layout: MainLayout,
    routes: [
      createRoute({
        menuName: 'Home',
        path: '',
        component: AsyncHome,
        when: LOGGED,
        exact: true,
        name: 'index',
      }),
      createRoute({
        menuName: 'Pengguna',
        path: '/user',
        component: AsyncUser,
        when: LOGGED,
        exact: true,
        name: 'user',
        permissions: ['access.user'],
        routes: [
          {
            menuName: 'Pengguna',
            path: '',
            exact: true,
            name: '',
          },
          {
            path: '/pengguna',
            component: AsyncAbout,
            name: '.pengguna',
          },
          {
            path: '/ada',
            component: AsyncAbout,
            name: '.ada',
          },
        ],
      }),
      createRoute({
        menuName: 'Brand',
        path: '/brand',
        component: AsyncBrand,
        when: LOGGED,
        name: 'brand',
      }),
      createRoute({
        menuName: 'Kupon',
        path: '/coupon',
        component: AsyncCoupon,
        when: LOGGED,
        name: 'coupon',
      }),
      createRoute({
        menuName: 'Pelanggan',
        path: '/customer',
        component: AsyncCustomer,
        when: LOGGED,
        name: 'customer',
      }),
      createRoute({
        menuName: 'Kategori Produk',
        path: '/product/category',
        component: AsyncProductCategory,
        when: LOGGED,
        name: 'product.category',
      }),
      createRoute({
        menuName: 'Detail',
        path: `/product/:id${regexParam.uuid}/view`,
        component: AsyncProductEdit,
        when: LOGGED,
        name: 'product.detail',
      }),
      createRoute({
        menuName: 'Tambah',
        path: '/product/add',
        component: AsyncProductAdd,
        when: LOGGED,
        name: 'product.add',
      }),
      createRoute({
        menuName: 'Produk',
        path: '/product',
        component: AsyncProduct,
        when: LOGGED,
        exact: true,
        name: 'product',
      }),
      createRoute({
        menuName: 'Ecommerce',
        path: '/ecommerce',
        component: AsyncEcommerce,
        when: LOGGED,
        exact: true,
        name: 'ecommerce.config',
        permissions: ['access.setting.product-ecommerce'],
      }),
      createRoute({
        menuName: 'Tambah',
        path: '/order/invoice/create',
        component: AsyncOrderCreate,
        when: LOGGED,
        exact: true,
        name: 'order.invoice.create',
      }),
      createRoute({
        menuName: 'Detail',
        path: `/order/invoice/:id${regexParam.uuid}/detail`,
        component: AsyncOrderDetail,
        when: LOGGED,
        exact: true,
        name: 'order.invoice.detail',
      }),
      createRoute({
        menuName: 'Penjualan',
        path: '/order/sales',
        component: AsyncOrderList,
        when: LOGGED,
        exact: true,
        name: 'order.invoice',
      }),
      createRoute({
        menuName: 'Tentang',
        path: '/about',
        component: AsyncAbout,
        when: LOGGED,
        name: 'about',
      }),
      createRoute({
        menuName: 'Laporan Penjualan',
        path: '/report/order',
        component: AsyncOrderReport,
        when: LOGGED,
        name: 'report.sales',
      }),
      createRoute({
        menuName: 'Rangkuman Produk',
        path: '/report/product-summary',
        component: AsyncReportProductSummary,
        when: LOGGED,
        name: 'report.product-summary',
        permissions: ['access.report-product'],
      }),
      createRoute({
        menuName: 'Rangkuman Penjualan',
        path: '/report/order-summary',
        component: AsyncReportOrderSummary,
        when: LOGGED,
        name: 'report.sales-order',
        permissions: ['access.report-sales'],
      }),
      createRoute({
        menuName: 'Laba Rugi',
        path: '/report/profit-loss',
        component: AsyncReportProfitLoss,
        when: LOGGED,
        name: 'report.profit-loss',
        permissions: ['access.profit-loss'],
      }),
      createRoute({
        menuName: 'Live Stok',
        path: '/report/stock',
        component: AsyncLiveStock,
        when: LOGGED,
        name: 'report.live-stock',
      }),
      // createRoute({
      //   menuName: 'Cashflow',
      //   path: '/finance/cashflow',
      //   component: AsyncCashflow,
      //   when: LOGGED,
      //   name: 'finance.cashflow',
      //   permissions: ['access.cashflow'],
      // }),
      createRoute({
        menuName: 'Jenis Kas',
        path: '/finance/cashtypes',
        component: AsyncCashType,
        when: LOGGED,
        name: 'finance.cashtype',
        permissions: ['access.cash-type'],
      }),
      createRoute({
        menuName: 'Biaya',
        path: '/finance/expenses',
        component: AsyncCashflowCredit,
        when: LOGGED,
        name: 'finance.cashflow.credit',
        permissions: ['access.cashflow'],
      }),
      createRoute({
        menuName: 'Penghasilan Lainnya',
        path: '/finance/incomes',
        component: AsyncCashflowDebit,
        when: LOGGED,
        name: 'finance.cashflow.debit',
        permissions: ['access.cashflow'],
      }),
      createRoute({
        menuName: 'Transaksi',
        path: '/finance/transaction',
        component: AsyncCashflowTransaction,
        when: LOGGED,
        name: 'finance.cashflow.transaction',
        permissions: ['access.cashflow'],
      }),
      createRoute({
        menuName: 'Riwayat Stock',
        path: '/stock-history',
        component: AsyncStockHistory,
        when: LOGGED,
        name: 'stock-history',
      }),
      createRoute({
        menuName: 'Detail',
        path: `/stock-adjustment/:id${regexParam.uuid}/detail`,
        component: AsyncStockAdjustmentDetail,
        when: LOGGED,
        name: 'stock-adjustment.detail',
      }),
      createRoute({
        menuName: 'Tambah',
        path: '/stock-adjustment/create',
        component: AsyncStockAdjustmentCreate,
        when: LOGGED,
        name: 'stock-adjustment.create',
      }),
      createRoute({
        menuName: 'Pengelolaan Stock',
        path: '/stock-adjustment',
        component: AsyncStockAdjustment,
        when: LOGGED,
        name: 'stock-adjustment',
      }),
      createRoute({
        menuName: 'Guest Account',
        path: '/guest-account',
        component: AsyncGuestAccount,
        when: LOGGED,
        name: 'guest-account',
      }),
    ],
  },
  {
    path: '/auth',
    layout: AuthLayout,
    routes: [
      // createRoute({
      //   path: '/register',
      //   component: AsyncRegister,
      //   when: GUEST,
      //   name: 'register',
      // }),
      createRoute({
        path: '/login',
        component: AsyncLogin,
        when: GUEST,
        exact: true,
        name: 'login',
      }),
      // createRoute({
      //   path: '/forgotPassword',
      //   component: AsyncForgotPass,
      //   when: GUEST,
      //   name: 'forgotpassword',
      // }),
    ],
  },
];

// const namedRoute = {};
// const allRoute = {};

// const recursiveRoute = (listRoute, prefix = '') => {
//   listRoute.map(theRoute => {
//     namedRoute[theRoute.name] = `${prefix}${theRoute.path}`;
//     allRoute[`${prefix}${theRoute.path}`] = theRoute.menuName;
//     if (theRoute.routes.length > 0) {
//       recursiveRoute(theRoute.routes, prefix);
//     }
//   });
// };

// /**
//  *
//  * @param {String} name
//  * @param {Object} params
//  */
// const OrouteName = (name, params) => {
//   // recursiveRoute(routes);
//   routes.map(route =>
//     route.routes.map(r => {
//       namedRoute[r.name] = `${route.path}${r.path}`;
//       allRoute[`${route.path}${r.path}`] = r.menuName;
//       if (r.routes.length > 0) {
//         recursiveRoute(r.routes, route.path);
//       }
//     }),
//   );
//   return generatePath(namedRoute[name] || `/${name}`, params);
// };

class RouterModule {
  constructor(routes) {
    this.routes = routes;
    this.namedRoute = {};
    this.allRoute = [];

    this.generateRoutes = this.generateRoutes.bind(this);
    this.routeName = this.routeName.bind(this);
    this.getNamedRoute = this.getNamedRoute.bind(this);
    this.getRoutes = this.getRoutes.bind(this);
  }
  generateRoutes() {
    const recursiveRoute = (listRoute, prefix = '') => {
      const layoutRoute = {};
      listRoute.forEach(theRoute => {
        this.namedRoute[theRoute.name] = `${prefix}${theRoute.path}`;
        layoutRoute[`${prefix}${theRoute.path}`] = theRoute.menuName;
        if (theRoute.routes.length > 0) {
          const rRoute = recursiveRoute(theRoute.routes, prefix);
          Object.keys(rRoute).forEach(rr => {
            layoutRoute[rr] = rRoute[rr];
          });
        }
      });
      return layoutRoute;
    };
    routes.forEach(route => {
      const layoutRoute = {};
      route.routes.forEach(r => {
        this.namedRoute[r.name] = `${route.path}${r.path}`;
        layoutRoute[`${route.path}${r.path}`] = r.menuName;
        if (r.routes.length > 0) {
          const rRoute = recursiveRoute(r.routes, route.path);
          Object.keys(rRoute).forEach(rr => {
            layoutRoute[rr] = rRoute[rr];
          });
        }
      });
      this.allRoute.push({ path: route.path, routes: layoutRoute });
    });
  }
  routeName(name, params) {
    return generatePath(this.namedRoute[name] || `/${name}`, params);
  }
  getNamedRoute() {
    return this.namedRoute;
  }
  getRoutes(layout) {
    if (layout) {
      const layoutRoute = this.allRoute.find(
        r => r.path === `/${layout.replace(/^\//, '')}`,
      );
      if (layoutRoute) {
        return layoutRoute.routes;
      }
      return {};
    }
    return this.allRoute.reduce((p, r) => ({ ...p, ...r.routes }), {});
  }
}

const routerModule = new RouterModule(routes);

routerModule.generateRoutes();

export const routeName = routerModule.routeName;
export const namedRoute = routerModule.getNamedRoute();
export const allRoutes = routerModule.getRoutes();
export const getRoute = routerModule.getRoutes;

export default routes;
