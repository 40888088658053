import React, { Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Spin } from 'antd';
import RouteBasic from '~/components/Route/RouteBasic';
import routes, { getRoute } from '~/config/routes';
import NotFound404 from './404';

const Document = () => {
  return (
    <Suspense fallback={<Spin size="large" className="zs-layout-spin" />}>
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
        {routes.map((route, i) => (
          <Route
            key={`parent-${route.path}-${i}`}
            exact={route.routes.some(r => r.exact)}
            path={Object.keys(getRoute(route.path))}
            // path={`${route.path}/*`}
          >
            <route.layout>
              <RouteBasic path={route.path || ''} routes={route.routes} />
            </route.layout>
          </Route>
        ))}
        <Route component={NotFound404} />
      </Switch>
    </Suspense>
  );
};

export default Document;
