import React from 'react';
import { Link, withRouter, matchPath } from 'react-router-dom';
import { Breadcrumb as Bread, Icon } from 'antd';
import PropTypes from 'prop-types';
import { getRoute } from '~/config/routes';

const Breadcrumb = ({ location, match }) => {
  const realPath = location.pathname.split('/').filter(i => i);
  const regexPath = match.path.split('/').filter(i => i);
  const dashboard = getRoute('dashboard');
  const routeBreadcrumb = [];
  regexPath.forEach((_, index) => {
    const urlRegex = `/${regexPath.slice(0, index + 1).join('/')}`;
    const url = `/${realPath.slice(0, index + 1).join('/')}`;
    const checkPath = matchPath(url, { path: urlRegex });
    if (checkPath && dashboard[checkPath.path]) {
      routeBreadcrumb.push(
        <Bread.Item key={url}>
          <Link to={url}>{dashboard[checkPath.path]}</Link>
        </Bread.Item>,
      );
    }
  });
  if (routeBreadcrumb.length > 4) {
    routeBreadcrumb.splice(0, routeBreadcrumb.length - 4);
  }
  const breadcrumbItems = [
    <Bread.Item key="home">
      <Icon type="home" />
    </Bread.Item>,
    ...(routeBreadcrumb.length > 4
      ? [<Bread.Item key="home">...</Bread.Item>]
      : []),
  ].concat(routeBreadcrumb);
  return <Bread>{breadcrumbItems}</Bread>;
};

Breadcrumb.propTypes = {
  location: PropTypes.object,
  match: PropTypes.object,
};

export default withRouter(Breadcrumb);
