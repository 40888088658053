import { createMenu, createComponent, createSubmenu } from '../utils/general';
import { LOGGED } from './constants';
import { HeaderUser } from '~/components/Layout';
import { routeName } from './routes';

const menus = () => ({
  primary: [
    createMenu(routeName('index'), 'Home', 'home', LOGGED),

    createMenu(routeName('order.invoice'), 'Penjualan', 'shopping', LOGGED),

    createSubmenu('Manajemen Produk', 'skin', [
      createMenu(
        routeName('product.category'),
        'Kategori Produk',
        'folder',
        LOGGED,
      ),
      createMenu(routeName('product'), 'Katalog Produk', 'folder', LOGGED),
      createMenu(
        routeName('ecommerce.config'),
        'Ecommerce Setting',
        'folder',
        LOGGED,
      ),
    ]),

    createSubmenu('Stok', 'block', [
      createMenu(
        routeName('stock-adjustment'),
        'Pengelolaan Stock',
        'folder',
        LOGGED,
      ),
      createMenu(
        routeName('report.live-stock'),
        'Live Stock',
        'folder',
        LOGGED,
      ),
      createMenu(routeName('stock-history'), 'Riwayat Stock', 'folder', LOGGED),
    ]),

    createSubmenu('Laporan', 'bar-chart', [
      createMenu(routeName('report.sales'), 'Penjualan', 'folder', LOGGED),
      createMenu(
        routeName('report.product-summary'),
        'Rangkuman Produk',
        'folder',
        LOGGED,
      ),
      createMenu(
        routeName('report.sales-order'),
        'Rangkuman Penjualan',
        'folder',
        LOGGED,
      ),
      createMenu(
        routeName('report.profit-loss'),
        'Laba Rugi',
        'folder',
        LOGGED,
      ),
    ]),

    createSubmenu('Finance', 'rise', [
      createMenu(routeName('finance.cashtype'), 'Jenis Kas', 'folder', LOGGED),
      createMenu(
        routeName('finance.cashflow.credit'),
        'Biaya',
        'folder',
        LOGGED,
      ),
      createMenu(
        routeName('finance.cashflow.debit'),
        'Penghasilan',
        'folder',
        LOGGED,
      ),
      createMenu(
        routeName('finance.cashflow.transaction'),
        'Transaksi',
        'folder',
        LOGGED,
      ),
      // createMenu(
      //   routeName('finance.cashflow.debit'),
      //   'Penghasilan Lainnya',
      //   'folder',
      //   LOGGED,
      // ),
    ]),

    createSubmenu('Manajemen', 'team', [
      createMenu(routeName('user'), 'User / Pengguna', 'folder', LOGGED),
      createMenu(routeName('brand'), 'Brand', 'folder', LOGGED),
      createMenu(routeName('coupon'), 'Kupon', 'folder', LOGGED),
      createMenu(routeName('customer'), 'Pelanggan / Mitra', 'folder', LOGGED),
      createMenu(routeName('guest-account'), 'Guest Account', 'folder', LOGGED),
    ]),

    createMenu(routeName('about'), 'About', 'folder', LOGGED),
  ],
  header: [
    // createMenu(routeName('index'), 'Notification', 'notification', LOGGED),
    createComponent(() => HeaderUser, LOGGED),
  ],
});

export default menus;
