import qs from 'qs';
import { history } from '~/config/store';

export const validateEmail = email => {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

/**
 *
 * @param {String} string
 */
export const camelToSpace = string =>
  String(string)
    .replace(/([A-Z])/g, ' $1')
    .replace(/^./, function(str) {
      return str.toUpperCase();
    });

/**
 * Create numberFormat from string
 *
 * @param {String} string
 * @param {String} separator
 */
export const numberFormat = (string, separator = '.') =>
  String(string).replace(/\B(?=(\d{3})+(?!\d))/g, separator);

/**
 * Create dot notation from path
 *
 * @param {String} path
 */
export const toDotNotation = path =>
  String(path)
    .replace(/\//g, '.')
    .replace(/^./, '')
    .replace(/[:|;|?|/|]/g, '')
    .replace(/\(([^)]+)\)/g, '');

export const regexParam = {
  uuid:
    '([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})',
};

/**
 * Return query string parse
 *
 * @param {String} search
 */
export const queryString = search =>
  qs.parse(search || history.location.search, {
    ignoreQueryPrefix: true,
  });

/**
 * @param {Object} query
 */
export const addQuery = (query = {}, replace = false) => {
  const { pathname, search } = history.location;

  const newSearch =
    replace === true
      ? query
      : {
          ...(queryString(search) || {}),
          ...query,
        };

  history.push({
    pathname,
    search: `?${qs.stringify(newSearch)}`,
  });
};

/**
 * @param {...String} queryNames
 */
export const removeQuery = (...queryNames) => {
  const { pathname, search } = history.location;
  const newSearch = queryString(search) || {};
  queryNames.map(q => delete newSearch[q]);
  history.push({
    pathname,
    search: `?${qs.stringify(newSearch)}`,
  });
};
