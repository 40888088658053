import { init } from '@rematch/core';
import createRematchPersist from '@rematch/persist';
import createLoadingPlugin from '@rematch/loading';
import { createBrowserHistory } from 'history';
import * as models from '~/models';

const persistPlugin = createRematchPersist({
  whitelist: ['auth'],
  keyPrefix: '--persist-key-cubezs-',
  throttle: 500,
  version: 1,
});

const loadingPlugin = createLoadingPlugin({});

export const history = createBrowserHistory();

const store = init({
  models,
  plugins: [persistPlugin, loadingPlugin],
});

export default store;
