import React, { Fragment } from 'react';
import { Menu, Icon } from 'antd';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useMenu } from './hooks';
import SubMenu from 'antd/lib/menu/SubMenu';

const MenuPrimary = ({ pathname, ...rest }) => {
  const menus = useMenu('primary');
  return (
    <Fragment>
      <Menu
        mode="inline"
        className="zs-side-menu"
        selectedKeys={[pathname || '/']}
        {...rest}>
        {menus.map(item => {
          if (item.hasSubmenu) {
            return (
              <SubMenu
                key={item.title}
                title={
                  <span>
                    {item.icon && <Icon type={item.icon} />} {item.title}
                  </span>
                }>
                {item.submenus.map(
                  submenu => submenu.path && renderMenu(submenu),
                )}
              </SubMenu>
            );
          } else if (item.path) {
            return renderMenu(item);
          }
          return '';
        })}
      </Menu>
      {menus.map(
        ({ component, index }) =>
          component && React.createElement(component(), { key: index }, null),
      )}
    </Fragment>
  );
};

const renderMenu = item => (
  <Menu.Item key={item.path}>
    <Link to={item.path}>
      {item.icon && (
        <Icon
          type={item.icon}
          // theme="twoTone"
          // twoToneColor={colorScheme.primaryColor}
          // style={{ fontSize: '1.2rem' }}
        />
      )}
      <span className="nav-text">{item.title}</span>
    </Link>
  </Menu.Item>
);

MenuPrimary.propTypes = {
  pathname: PropTypes.string,
};

export default MenuPrimary;
