import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, Dropdown, Menu, Icon } from 'antd';
import { connect } from 'react-redux';

const MenuDrop = ({ logout, user }) => {
  return (
    <div
      className="ant-card ant-card-bordered zs-header-user-dropdown"
      style={{ marginTop: -15, width: 200, borderRadius: 5 }}>
      <div className="ant-card-head">
        <div className="ant-card-head-wrapper">
          <div className="ant-card-head-title">
            <h5 className="username">{user.name || user.username}</h5>
            <small className="userrole">{user.role && user.role.title}</small>
          </div>
        </div>
      </div>
      <div className="ant-card-body" style={{ padding: 0 }}>
        <Menu type="inline">
          <Menu.Item>
            <Icon type="user" />
            Ubah profile
          </Menu.Item>
          <Menu.Item>
            <Icon type="key" />
            Ubah Password
          </Menu.Item>
          <Menu.Item onClick={logout}>
            <Icon type="logout" />
            Logout
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
};
MenuDrop.propTypes = {
  logout: PropTypes.func,
  user: PropTypes.object,
};

const HeaderUser = ({ logout, user }) => {
  return (
    <Dropdown
      overlay={() => <MenuDrop logout={logout} user={user} />}
      placement="bottomRight">
      <div className="zs-header-user">
        <Avatar
          src={`https://ui-avatars.com/api/?name=${user.name || user.username}`}
        />
        <span style={{ paddingLeft: 8, fontWeight: 500 }}>
          Hi, {user.name || user.username}
        </span>
      </div>
    </Dropdown>
  );
};

HeaderUser.propTypes = {
  logout: PropTypes.func,
  user: PropTypes.object,
};

HeaderUser.displayName = 'HeaderUser';

export default connect(
  state => ({ user: state.auth.user }),
  dispatch => ({
    logout: () => dispatch.auth.logout(),
  }),
)(HeaderUser);
