import React, { Suspense, useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import { GUEST, LOGGED } from '~/config/constants';
import { useAuthenticated } from '~/components/Auth';
import Unauthorized401 from '~/pages/401';
import NotFound404 from '~/pages/404';
import { history } from '~/config/store';
import instance from '~/services/instance';
import { connect } from 'react-redux';

const RouteBasic = ({ routes, path, getMe, getPermissions }) => {
  const { isAuthenticated, token, permissions = [] } = useAuthenticated();

  useEffect(() => {
    if (token) {
      instance.setToken(token);
      getMe();
      getPermissions();
    }
    // return () => {
    //   instance.removeToken();
    // };
  }, [getMe, getPermissions, token]);

  return (
    <Suspense fallback={<Spin size="large" className="zs-layout-spin" />}>
      <Switch>
        {routes.map(route => (
          <Route
            key={route.index}
            strict={route.routes.length === 0}
            // set exact based on parent and children if true
            exact={[route, ...route.routes].some(r => r.exact)}
            // exact={route.exact}
            // combine path based on parent and all children
            path={[
              `${path}${route.path}`,
              ...(route.routes.map(r => `${path}${r.path}`) || []),
            ]}
            // render component based on when and auth and permssions if exist
            render={props => {
              if (
                route.when === undefined ||
                route.when === null ||
                (isAuthenticated === false && route.when === GUEST)
              ) {
                // public / guest
                if (!route.component || route.component === 'nocontent') {
                  return React.createElement('nocontent');
                }
                return React.createElement(route.component, props, null);
              } else if (isAuthenticated === true && route.when === LOGGED) {
                // authenticated with role
                if (
                  route.permissions.length === 0 ||
                  (route.permissions.length > 0 &&
                    permissions.length > 0 &&
                    (permissions.includes('*') ||
                      route.permissions.some(perm =>
                        permissions.includes(perm),
                      )))
                ) {
                  if (!route.component || route.component === 'nocontent') {
                    return React.createElement('nocontent', {
                      style: { display: 'none' },
                    });
                  }
                  return React.createElement(
                    route.component,
                    { ...props, routes: route.routes || [], path },
                    null,
                  );
                } else {
                  // authenticated and unauthorized
                  return React.createElement(Unauthorized401, props, null);
                }
              } else if (isAuthenticated === true && route.when === GUEST) {
                return <Redirect to="/dashboard" />;
              } else {
                // redirect to login
                return (
                  <Redirect
                    to={{
                      pathname: '/auth/login',
                      state: { from: history.location },
                    }}
                  />
                );
              }
            }}
          />
        ))}
        {/* handle route that match parent without children with empty component */}
        {/* {routes.length > 0 && (
          <Route path={`(${[path, ...routes.map(r => r.path)].join('|')})`} />
        )} */}
        {/* handle every route that not match children */}
        <Route path={`${path}`} component={NotFound404} />
      </Switch>
    </Suspense>
  );
};

RouteBasic.propTypes = {
  routes: PropTypes.array,
  path: PropTypes.string,
  getMe: PropTypes.func,
  getPermissions: PropTypes.func,
};

export default connect(
  undefined,
  dispatch => ({
    getMe: () => dispatch.auth.getMe(),
    getPermissions: () => dispatch.auth.getPermissions(),
  }),
)(RouteBasic);
