import { toDotNotation } from './string';

export const nextNumber = (next = 1) => () => next++;

const nextRouteIndex = nextNumber();
export const createRoute = ({
  path,
  component,
  when = null,
  exact = false,
  permissions = [],
  routes = [],
  name,
  menuName,
}) => ({
  index: nextRouteIndex(),
  path,
  component,
  when,
  exact,
  permissions,
  routes:
    routes.map(r =>
      createRoute({
        ...r,
        path: `${path}${r.path}`,
        name: `${name}${r.name || toDotNotation(r.path)}`,
      }),
    ) || [],
  name: name || (path !== '/' ? toDotNotation(path) : 'index' + nextRouteIndex),
  menuName,
});

const nextMenuIndex = nextNumber();
export const createMenu = (url, title, icon, when) => ({
  index: nextMenuIndex(),
  title,
  path: url,
  icon,
  when,
});
export const createSubmenu = (title, icon, routes) => ({
  index: nextMenuIndex(),
  title,
  icon,
  hasSubmenu: true,
  submenus: routes,
});

export const createComponent = (component, when) => ({
  index: nextMenuIndex(),
  component,
  when,
});

export const sortString = key => (a, b) =>
  a[key] ? a[key].localeCompare(b[key]) : true;
export const sortNumber = key => (a, b) => a[key] - b[key];
export const sortBool = key => (a, b) => b[key] - a[key];
export const updatedObject = (n, o) =>
  Object.keys(n).filter(
    // k => typeof n[k] === 'object' || (n[k] !== o[k] && o[k] !== undefined),
    k => typeof n[k] === 'object' || n[k] !== o[k],
  );
export const updatedValues = (n, o) =>
  updatedObject(n, o).reduce(
    (o, f) => ({
      ...o,
      [f]: n[f],
    }),
    {},
  );
