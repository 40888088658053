import React from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from 'antd';

const Title = ({ text, loading = false }) => (
  <h1 className="zs-title">
    <Skeleton active title={{ width: 1 }} loading={loading}>
      {text}
    </Skeleton>
  </h1>
);

Title.propTypes = {
  text: PropTypes.string.isRequired,
  loading: PropTypes.bool,
};

export default Title;
