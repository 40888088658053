import findIndex from 'lodash/findIndex';

const adjustmentForm = {
  state: {
    items: [],
    effect: 0,
  },
  reducers: {
    addItemIncrement: (state, payload) => {
      const findItemIndex = findIndex(state.items, {
        item: { variation: { id: payload.item.variation.id } },
      });
      let newItems;
      if (findItemIndex > -1) {
        newItems = [...state.items];
        newItems[findItemIndex].amount += payload.amount;
      } else {
        newItems = [...state.items, payload];
      }
      return {
        ...state,
        items: newItems,
        effect: Math.random(),
      };
    },
    addItem: (state, payload) => {
      return {
        ...state,
        items: [...state.items, payload],
        effect: Math.random(),
      };
    },
    removeItem: (state, index) => {
      const newItems = state.items;
      newItems.splice(index, 1);
      return {
        ...state,
        items: newItems,
        effect: Math.random(),
      };
    },
    updateItem: (state, item) => {
      const newItems = state.items;
      newItems[item.index][item.key] = item.value;
      return {
        ...state,
        items: newItems,
        effect: Math.random(),
      };
    },
    clearAllItems: () => ({
      items: [],
      effect: Math.random(),
    }),
  },
  effects: {},
};

export default adjustmentForm;
