import React from 'react';
import PropTypes from 'prop-types';
import { Layout } from 'antd';

const AuthLayout = ({ children }) => {
  return <Layout className="zs-layout">{children}</Layout>;
};

AuthLayout.propTypes = {
  location: PropTypes.object,
  children: PropTypes.node,
};

export default AuthLayout;
