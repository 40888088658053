import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Row, Col, Layout, Result, Button } from 'antd';
// import { Logo, PrimaryLogo } from '~/components/Shared';
// import { MenuPrimary } from '~/components/Layout';
// import bg from '~/img/bg.jpg';
import { routeName } from '~/config/routes';
import store from '~/config/store';

// const { Content, Sider } = Layout;
const { Content } = Layout;

const NotFound404 = ({ location, history }) => {
  // const { pathname } = location;

  const [auth] = useState(store.getState().auth.isAuthenticated);

  if (auth) {
    return (
      // <Layout className="zs-layout">
      //   <Sider
      //     width={250}
      //     trigger={null}
      //     breakpoint="md"
      //     collapsedWidth="0"
      //     collapsed={false}
      //     style={{ background: `url(${bg})` }}
      //     className="zs-layout-sider">
      //     <Logo component={<PrimaryLogo fill="#4a4949" />} />
      //     <div className="scrollable-menu">
      //       <strong className="zs-menu-title">Dashboard</strong>
      //       <MenuPrimary pathname={pathname} />
      //     </div>
      //   </Sider>
      //   <Layout className="zs-layout-section" style={{ marginLeft: 250 }}>
      //     <Layout.Header className="zs-header" />
      <Content className="zs-layout-content">
        <Result
          status="404"
          title="404"
          subTitle="Sorry, the page you visited does not exist."
          extra={
            <Button
              type="primary"
              onClick={() => history.push(routeName('index'))}>
              Back Home
            </Button>
          }
        />
      </Content>
      //   </Layout>
      // </Layout>
    );
  }

  return (
    <Row
      type="flex"
      align="middle"
      justify="center"
      style={{ height: '100vh' }}>
      <Col>
        <strong style={{ fontSize: '1.5rem' }}>Page Not Found | 404</strong>
      </Col>
    </Row>
  );
};

NotFound404.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
};

export default withRouter(NotFound404);
