export const auth = {
  login: '/auth/login',
  logout: '/auth/logout',
  me: '/auth/me',
  refresh: '/auth/refresh',
  permissions: '/users/permissions',
};

export const user = {
  all: '/users',
};

export const brand = {
  all: '/brands',
};

export const coupon = {
  all: '/coupons',
};

export const product = {
  all: '/products',
};

export const productVariation = {
  all: '/products/variations',
  updateStock: '/products/variations/update-stock',
};

export const productCategory = {
  all: '/products/categories',
};

export const productPrice = {
  all: '/products/prices',
};

export const customer = {
  all: '/customers',
};

export const order = {
  create: '/orders/create',
  list: '/orders',
  get: '/orders',
};

export const cashflow = {
  all: '/finance/cashflows',
};

export const cashtype = {
  all: '/finance/cash-types',
};

export const subscriberAccount = {
  all: '/subscriber-accounts',
};

export const stockAdjustmentForm = {
  all: '/products/stocks/adjustment/form',
  get: '/products/stocks/adjustment/form',
  create: '/products/stocks/adjustment/form',
  delete: '/products/stocks/adjustment/delete',
};
