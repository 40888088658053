import { Button, Form, Icon, Input } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { Title } from '../Shared';

function Login({ form, loading, authentication }) {
  const { getFieldDecorator } = form;
  const handleSubmit = e => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        authentication(values.user, values.password);
      }
    });
  };

  return (
    <Form onSubmit={handleSubmit} className="zs-form-login">
      <Title text="Login" />
      <Form.Item>
        {getFieldDecorator('user', {
          rules: [{ required: true }],
        })(
          <Input
            prefix={<Icon type="user" className="zs-prefix-icon" />}
            placeholder="Email or Username"
            size="large"
          />,
        )}
      </Form.Item>
      <Form.Item>
        {getFieldDecorator('password', {
          rules: [{ required: true }],
        })(
          <Input
            prefix={<Icon type="lock" className="zs-prefix-icon" />}
            type="password"
            size="large"
            placeholder="Password"
          />,
        )}
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          size="large"
          htmlType="submit"
          loading={loading}
          className="zs-button">
          Login
        </Button>
      </Form.Item>
    </Form>
  );
}

const formShape = {
  validateFields: PropTypes.func,
  getFieldDecorator: PropTypes.func,
};

Login.propTypes = {
  form: PropTypes.shape(formShape).isRequired,
  loading: PropTypes.bool,
  authentication: PropTypes.func,
};

export default Form.create({ name: 'normal_login' })(Login);
