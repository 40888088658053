import React, { Suspense, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
// import { LocaleProvider, Spin } from 'antd';
import { Spin, ConfigProvider, Icon } from 'antd';
import idID from 'antd/es/locale/id_ID';
import { getPersistor } from '@rematch/persist';
import { PersistGate } from 'redux-persist/integration/react';
// import { useTranslation } from 'react-i18next';
import Layout from './pages/Layout';
import store, { history } from './config/store';
// import getLocalesAntd from './config/localization/antdLocale';
import * as registerServiceWorker from './registerServiceWorker';
import './styles/index.less';
// import './config/localization/i18n';

Spin.setDefaultIndicator(<Icon type="loading" style={{ fontSize: 30 }} spin />);

const persistor = getPersistor();

const AppSuspense = () => (
  <Suspense fallback={<Spin size="small" className="zs-layout-spin" />}>
    <App />
  </Suspense>
);

const App = () => {
  useEffect(() => {
    document.title = process.env.REACT_APP_NAME || 'ERESTE269';
  }, []);

  // const { i18n } = useTranslation();
  return (
    // <LocaleProvider locale={getLocalesAntd(i18n.language)}>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ConfigProvider locale={idID}>
          <Router history={history}>
            <Layout />
          </Router>
        </ConfigProvider>
      </PersistGate>
    </Provider>
    // </LocaleProvider>
  );
};

ReactDOM.render(<AppSuspense />, document.getElementById('root'));
registerServiceWorker.register();
