import { Button, Form, Icon, Input } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { Title } from '../Shared';

const formShape = {
  validateFields: PropTypes.func,
  getFieldDecorator: PropTypes.func,
};

function Register(props) {
  const { getFieldDecorator } = props.form;

  const handleSubmit = e => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
      }
    });
  };

  return (
    <Form onSubmit={handleSubmit} className="zs-form-register">
      <Title text="Register" />
      <Form.Item>
        {getFieldDecorator('userName', {
          rules: [{ required: true }, { type: 'email' }],
        })(
          <Input
            prefix={<Icon type="mail" className="zs-prefix-icon" />}
            placeholder="Email"
            size="large"
          />,
        )}
      </Form.Item>
      <Form.Item>
        {getFieldDecorator('name', {
          rules: [{ required: true }],
        })(
          <Input
            prefix={<Icon type="user" className="zs-prefix-icon" />}
            placeholder="User"
            size="large"
          />,
        )}
      </Form.Item>
      <Form.Item>
        {getFieldDecorator('password', {
          rules: [{ required: true }],
        })(
          <Input
            prefix={<Icon type="lock" className="zs-prefix-icon" />}
            type="password"
            size="large"
            placeholder="Password"
          />,
        )}
      </Form.Item>
      <Form.Item>
        <Button
          className="zs-button"
          type="primary"
          size="large"
          htmlType="submit">
          Sign In
        </Button>
      </Form.Item>
    </Form>
  );
}

Register.propTypes = {
  form: PropTypes.shape(formShape).isRequired,
};

export default Form.create({ name: 'register' })(Register);
